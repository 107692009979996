








































































/* eslint-disable */
	import Vue from "vue";
	import { Route, NavigationGuard } from "vue-router";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";

	export default Vue.extend({
		components: { VuePerfectScrollbar },
	});
